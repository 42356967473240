export default [ 
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  { 
    title: 'Tarifas',
    route: 'rates',
    icon: 'DollarSignIcon',
  },
  { 
    title: 'Documentos',
    route: 'documents',
    icon: 'FileIcon',
  },
  // {
  //   title: 'Orden Trabajo',
  //   route: 'newworkorder',
  //   icon: 'FilePlusIcon',
  // },
  {
    title: 'OT Órdenes de Trabajo', 
    icon: 'ListIcon',
    children: [
      {
        title: 'OT Todas',
        route: { name: 'workorders-all', params: { type: 'All' } }       
      },
      {
        title: 'OT Oportunidades',
        route: { name: 'workorders-oportunities', params: { type: 'Oportunity' } }       
      },
      {
        title: 'OT en proceso',
        route: { name: 'workorders', params: { type: 'InProcess' } }    
      },
      {
        title: 'OT Presupuesto dado - pte aprobación',
        route: { name: 'workorders-budgetacceptpending', params: { type: 'budgetAcceptPending' } }    
      },
      {
        title: 'OT Presupuesto rechazado',
        route: { name: 'workorders-workorderrejected', params: { type: 'workOrderRejected' } }    
      },
      {
        title: 'OT Hechos',
        route: { name: 'workorders-completed', params: { type: 'completedBackend' } }    
      },      
      {
        title: 'OT Anulados',
        route: { name: 'workorders-nulls', params: { type: 'nulls' } }    
      },
    ]
  },
  {
    title: 'Contabilidad', 
    icon: 'ListIcon',
    children: [
      {
        title: 'OT Para liquidar',
        route: { name: 'workorders-toliquidate', params: { type: 'toLiquidate' } }    
      },
      {
        title: 'OT Pendiente de cobrar',
        route: { name: 'workorders-pendingpayment', params: { type: 'pendingPayment' } }    
      },
      {
        title: 'OT Servicio liquidado',
        route: { name: 'workorders-serviceliquidated', params: { type: 'liquidarBackend' } }    
      },
    ]
  },
  
]
